<template>
    <custom-modal
        width="600px"
        title="发起续保"
        :visible="visible"
        @on-close="handleClose"
    >
        <modal-content :backfill-info="backfillInfo" @on-ok="handleOk" />

        <a-space slot="customFooter">
            <a-button @click.stop="handleClose">取消</a-button>
            <custom-portal-target :name="portalTarget" />
        </a-space>
    </custom-modal>
</template>
<script>
import { Button as AButton, Space as ASpace } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
import CustomPortalTarget from '@components/CustomPortalVue/PortalTarget'

import ModalContent, { PORTAL_KEY } from './Content'

export default {
    name: 'InitiateRenewalModal',
    components: {
        ASpace,
        AButton,
        CustomModal,
        ModalContent,
        CustomPortalTarget
    },
    props: {
        visible: Boolean,
        backfillInfo: Object
    },
    data() {
        return {
            portalTarget: PORTAL_KEY
        }
    },
    methods: {
        handleClose() {
            this.$emit('on-close')
        },
        handleOk() {
            this.handleClose()
            this.$emit('on-refresh')
        }
    }
}
</script>
