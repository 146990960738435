<template>
    <a-form-model layout="vertical">
        <template v-for="column in backfillInfoColumns">
            <a-form-model-item :key="column.dataIndex" :label="column.label">
                <a-input disabled :value="getBackfillInfoText(column)" style="width: 72%" />
            </a-form-model-item>
        </template>

        <a-form-model-item label="保险期限">
            <date-range-picker
                v-model="insurancePeriodStartAndEndDate"
                style="width: 72%"
            />
        </a-form-model-item>

        <custom-portal :to="portalTo">
            <a-button
                type="primary"
                :loading="confirming"
                :disabled="confirmBtnDisabled"
                @click.stop="handleConfirm"
            >
                确定
            </a-button>
        </custom-portal>
    </a-form-model>
</template>
<script>
import { Input as AInput, Button as AButton, FormModel as AFormModel } from 'ant-design-vue'
import CustomPortal from '@components/CustomPortalVue/Portal'
import DateRangePicker from '@weights/CreateForm/components/DateRangePicker'

import { isEmpty, empty as emptyFilter } from '@utils'
import { amountFilter } from '@utils/filter'

import confirmRenewalService from '@service/marginManagement/marginPaymentDetail/confirmRenewalService'

export const PORTAL_KEY = 'INITIATE_RENEWAL'

const BACKFILL_INFO_COLUMNS = [
    {
        dataIndex: 'policyHolderName',
        label: '投保人',
        formatter: val => emptyFilter(val)
    },
    {
        dataIndex: 'projectName',
        label: '工程名称',
        formatter: val => emptyFilter(val)
    },
    {
        dataIndex: 'amountPayable',
        label: '保证金应缴金额',
        formatter: val => amountFilter(val)
    }
]

export default {
    name: 'InitiateRenewalModalContent',
    components: {
        AInput,
        AButton,
        AFormModel,
        CustomPortal,
        DateRangePicker,
        AFormModelItem: AFormModel.Item
    },
    props: {
        backfillInfo: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            portalTo: PORTAL_KEY,

            confirming: false,
            // 保险期限
            insurancePeriodStartAndEndDate: undefined,
            // 会填信息字段配置
            backfillInfoColumns: Object.freeze(BACKFILL_INFO_COLUMNS)
        }
    },
    computed: {
        // 订单编号
        orderNo() {
            return this.backfillInfo.orderNo
        },
        // 确认按钮禁用状态
        confirmBtnDisabled() {
            return isEmpty(this.insurancePeriodStartAndEndDate)
        }
    },
    methods: {
        async handleConfirm() {
            try {
                this.confirming = true

                const formData = {
                    orderNo: this.orderNo,
                    insurancePeriodStartAndEndDate: this.insurancePeriodStartAndEndDate
                }

                await confirmRenewalService(formData)

                this.$message.success('操作成功')
                this.$emit('on-ok')
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.confirming = false
            }
        },
        getBackfillInfoText({ dataIndex, formatter }) {
            return formatter(this.backfillInfo[dataIndex])
        }
    }
}
</script>
