import axios from '@service'
import { CONFIRM_RENEWAL } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

/** 确认（发起）续保 */
export default async function confirmRenewalService(payload = {}, config = {}) {
    const param = {
        ...CONFIRM_RENEWAL,
        ...config,
        data: payload
    }

    const { data } = await axios(param)

    if (data.code === HTTP_ERR_SUCCESS) {
        return data.result || {}
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
